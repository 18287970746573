#summary{
    width: 100%;
    margin-top: 8px;
    padding: 8px;
    section{
        display: flex;
        justify-content: space-between;
        p{
            margin: 6px 0;
            font-size: 16px;
            color: rgba(var(--ion-color-dark-rgb), 0.8);
            // color: #2F3137
        }
        &.grandtotal{
            border-top: .5px solid var(--ion-color-dark);
            p{
                font-weight: 500;
                font-size: 22px;
                color: var(--ion-color-dark);

            }
        }
    }
}