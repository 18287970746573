#map_modal_footer{
    padding: 16px;
    position: fixed;
    bottom: 0;
    background: linear-gradient(rgba(255, 255, 255, 0) 2.5%, rgba(255, 255, 255, 0.92) 55.35%);
    ion-button{
        height: 56px;
        --box-shadow: none;
        margin-bottom: 8px;
    }
}