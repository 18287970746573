#checkout_screen{
    .map_container{
        margin: 16px 16px 0px;
        border: 1px solid var(--ion-color-dark);
        width: 100%;
    }
    ion-list{
        width: 100%;
    }
    span.disclaimer{
        font-size: 12px;
        padding: 16px;
        line-height: 20px;
        color: rgba(var(--ion-color-dark-rgb), 0.5)
    }
}   