#order_image_cover{
    position: relative;
    width: 100%;
    ion-img{
        transition: transform .2s;
        &:hover{
            transform: scale(1.01); 
        }
        &::part(image){
            height: 128px;
            object-fit: cover;
        }
    }
    ion-button{
        --padding-end: 12px;
        --padding-start: 12px;
        // width: 36px;
        position: absolute;
        right: 8px;
        bottom: 8px;
        text-transform: none;
    }
}