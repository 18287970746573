#order_item{
    display: flex;
    margin: 8px 16px;
    cursor: pointer;
    align-items: center;
    .order_item_image{
        margin-right: 12px;
        display: flex;
        ion-img{
            width: 96px;
            height: 96px;
            object-fit: cover;
        }
        justify-content: center;
        align-items: center;
        transition: transform .2s;
        &:hover{
            transform: scale(1.02); 
        }
    }
    .order_item_description{
        h3{
            margin: 0;
            font-size: 16px;
        }
        p{
            margin: 0;
            padding-top: 4px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2; /* number of lines to show */
            -webkit-box-orient: vertical;
            font-size: 14px;
            color: rgba(var(--ion-color-dark-rgb), 0.5);
            line-height: 20px;
        }
        span{
            font-size: 14px;
            padding-top: 8px;
            font-weight: 500;
        }
    }
    ion-button{
        --box-shadow: none;
        margin-left:auto;
        text-transform: none;
    }
}

@media (min-width: 600px) {
    #order_item{
        transition: transform .2s;
        &:hover{
            transform: scale(1.02);
            border: .1px solid var(--ion-color-dark); 
        }
    }
  }