#wala_header{
    ion-toolbar{
        ion-title{
            padding: 0;
        }
        &.transparent{
            --background: none;
            --border-color: transparent;
        }
    }
}