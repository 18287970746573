#category_title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 8px;
    width: 100%;
    h3{
        font-size: 22px;
        margin: 0;
        font-weight: 600;
    }
    ion-button{
        text-transform: none;
        --padding-end: 0;
        --padding-start: 0;
        color: var(--ion-color-success);    
    }
}