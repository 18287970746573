#option_select{
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 0 8px;
    &.column{
        flex-direction: column;
    }
    &.row{
        .option{
            width: 50%;
        }
    }
    .option{
        cursor: pointer;
        margin: 8px 8px 0 8px;
        border: 1px solid rgba(var(--ion-color-dark-rgb), 0.5);
        --inner-padding-top: 6px;
        --inner-padding-bottom: 6px;
        --padding-start: 0;
        p{
            margin: 0 0 0 12px;
            font-size: 14px;
        }
        &.selected{
            font-weight: 500;
            border: 2px solid var(--ion-color-success);
            color: var(--ion-color-success);
            // --background: var(--ion-color-dark);
            // --color: #ffffff;
        }
    }
}