#wala-logo{
        h3{
                font-family: 'Pacifico', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
                &.small{
                        font-size: 16px;
                }
                
                &.default{
                        font-size: 24px;
                }
        
                &.large{
                        font-size: 32px;
                }
                
                &.extra_large{
                        font-size: 64px;
                }
        
                &.success {
                        color: var(--ion-color-success);
                }
        
                &.light {
                        color: var(--ion-color-light);
                }
        }
        p{
                margin: -16px 25% 0 0;
                text-align: right;
                color: var(--ion-color-light);
                font-size: 18px;
        }
}