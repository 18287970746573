.category_item_skeleton{
    margin: 8px 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &_image{
        width: 60px;
        height: 60px;
        border-radius: 50%;
    }
    &_text{

    }
}