#item_cover_image{
    margin-top: -56px;
    ion-img {
        width: 100%;
        height: 310px;
        object-fit: cover; 
     }
}

@media (min-width: 600px) {
    #item_cover_image{
        position: -webkit-sticky;
        position: sticky;
        top: 20px;
        ion-img {
            width: 100%;
            height: 100%;
            object-fit: cover; 
         }
        margin-top: 0px;
    }
    
}

