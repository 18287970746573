ion-modal.map_modal {
    #ion-react-wrapper{
        .map_marker_fixed{
            left: 50%;
            margin-left: -24;
            margin-top: -48;
            position: absolute;
            top: 50%;
        }
      }
  }
  