#item_detail_icon{
    --inner-padding-bottom: 8px;
            --inner-padding-top: 8px;
            width: 100%;
            ion-label{
                h3{
                    font-weight: 500;
                    font-size: 16px;
                }
            }
}