#order_status_agent{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    p{
        color: var(--ion-color-dark);
        font-size: 16px;
        span{
            font-weight: 500;
        }
    }
    ion-img{
        width: 128px;
        height: 128px;
        &::part(image){
            border-radius: 50%;
        }
    }
    ion-button{
        width: 56px;
        height: 56px;
        margin: 0 16px;
        --box-shadow: none;
        &::part(native){
            padding: 0;
        }
    }
}