ion-searchbar#search_input {
    --border-radius: 100px;
    --background: var(--ion-color-medium);
    margin: 8px 0;
    .searchbar-input-container {
        height: 48px;

        input {
            font-size: 14px !important;
            padding-left: 40px !important;
        }

        ion-icon {
            width: 18px;
            margin-left: 8px !important;
            color: var(--ion-color-primary);

        }

        button.searchbar-clear-button {
            margin-right: 12px;
        }
    }
}