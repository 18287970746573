.wala-content {
    --padding-top: 8px;
    --padding-end: 8px;
    --padding-bottom: 0px;
    --padding-start: 8px;
    padding: 8px 8px 0 8px;
    width: 100%;
}

.wala-button{
    --box-shadow: none;
    height: 56px;
    text-transform: none;
}

.wala-custom-modal {
    padding-top: 24px;
        &::part(content){
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
        }
}

form{
    width: 100%;
}

.wala-input{
    --padding-start: 0;
    --inner-padding-end: 0;
    margin: 16px 0;
    font-size: 14px;
    ion-label {
        font-size: 16px !important;
        margin-bottom: 12px !important;
    }
    ion-note.required{
        font-size: 22px;
        font-weight: 500;
        top: 12px;
        margin-right: 12px;
        position: absolute;
    right: 0;
    }    
    // &:hover{
    //     border: 1px solid var(--ion-color-secondary-shade); 
    // }
    &.item-has-focus  {
        ion-label{
            color: var(--ion-color-dark) !important;
            font-weight: 500;
        }
        ion-input,
        ion-textarea{
            --background: var(--ion-color-medium);
         }
    }

    ion-input,
    ion-textarea{
        margin-top: 0px;
        --placeholder-color: var(--ion-color-dark);
        --padding-start: 8px !important;
        --padding-end: 8px;
        --background: #f4f4f4;
        min-height: 44px;  
 
    }
}
@media (min-width: 600px) {
    .wala-input{
        &.item-has-focus  {
            ion-input,
        ion-textarea{
            border: 2px solid var(--ion-color-secondary-shade); 
        }
        }
    }
}