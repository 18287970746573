#order_status_indicator{
    width: 100%;
    margin-bottom: 8px;
    display: flex;
    justify-content: space-around;

    ion-skeleton-text{
        width: 25%;
        height: 6px;
        margin: 0 8px;
        border-radius: 100px;
        &.current{
            background: var(--ion-color-success);
        }
    }
}