#estimated_arrival{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0 8px;
    width: 100%;
    p{
        font-size: 12px;
        color: rgba(var(--ion-color-dark-rgb), 0.5);
    }
}