#store_card{
    // max-width: 500px;
    min-width: 290px;
    margin: 8px 8px 12px 8px;
    max-height: 230px;
    cursor: pointer; 
    .store_card_image{
        position: relative;
        width: 100%;
        ion-img{
            transition: transform .2s;
            &:hover{
                transform: scale(1.01); 
            }
            &::part(image){
                // border-radius: 8px;
                height: 156px;
                object-fit: cover;
            }
        }
        ion-button{
            --padding-end: 0;
            --padding-start: 0;
            width: 36px;
            position: absolute;
            right: 8px;
            bottom: 8px;
            // backdrop-filter: saturate(180%) blur(2px);
        }
    }
    .store_card_header{
        .store_card_title{
            display: flex;
            justify-content: space-between;
            align-items: center;
            h3{
                font-size: 16px;
                margin: 0;
            }
            .store_card_rate{
                margin-top: 8px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                padding: 4px 8px;
                background: var(--ion-color-medium);
                span{
                    font-size: 14px;
                }
            }

        }
        .store_card_description{
            display: flex;
            margin-top: -8px;
            span{
                color: rgba(var(--ion-color-dark-rgb), 0.5);
                font-size: 14px;
            }
            .store_card_fee{
                margin-right: 4px;
                font-weight: 500;
            }
        }
    }
}