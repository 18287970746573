#category_item{
    display: flex;
    margin: 8px 0;
    cursor: pointer;
    &.default{
        width: 80px;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .category_img_container:hover{
                border-radius: 50%;
                background: var(--ion-color-medium);
    
            }
    }

    &.large{
        width: 170px;
        height: 64px;
        flex-direction: revert;
        align-items: center;
        flex-direction: row-reverse;
        justify-content: space-between;
        padding: 8px;
        background: var(--ion-color-medium);
        border-radius: 8px;
        h3{
            margin: auto 0;
        }
    }
    .category_img_container{
        width: 60px;
        height: 60px;
        padding: 8px;
    }
    h3{
        text-align: center;
        font-size: 14px;
        margin: 8px 0;
    }
}