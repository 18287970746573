#order_header_info{
    padding: 16px;
    h3{
        margin: 0;
        font-size: 34px;
    }
    p{
        margin: 0;
        color: var(--ion-color-success);
        span{
            color: rgba(var(--ion-color-dark-rgb), 0.5);
        }
    }
}