#header_condense{
    ion-toolbar{
        --padding-top: 0;
        padding-top: 0;
        h3{
            margin: 0 12px 8px 12px;
            font-size: 36px;
            font-weight: 500;
        }
    }
}