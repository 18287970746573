#item_option{
    .item_option_header{
        --background: var(--ion-color-medium);
        --padding-bottom: 16px; 
        --padding-end: 0px; 
        --padding-start: 16px; 
        --padding-top: 16px;
        margin-bottom: -24px;
        .item_option_title{
            flex-direction: column;
            h3{
                margin: 0;
                font-size: 18px;
            }
            p{
                margin: 0;
                font-size: 14px;
                color: rgba(var(--ion-color-dark-rgb), 0.5);
            }
        }
    }
    // .item_option_list{
    //     margin-top: -16px;
    // }
}