#promotional_banner{
    border-radius: 12px;
    display: flex;
    min-width: 280px;
    max-width: 380px;
    height: 160px;
    margin: 0 16px;
    .promotional_banner_content{
        display: flex;
        flex: 1 1 0px;
        flex-direction: column;
        padding: 12px;
        height: 100%;
        h3{
            font-size: 18px;
            margin: 0;
            font-weight: 600;
        }
        p{
            margin: 0;
            font-size: 14px;
        }
        ion-button{
            margin-top: auto;
            max-width: 120px;
        }
    }
    ion-img{
        &::part(image){
            border-bottom-right-radius: 12px;
            border-top-right-radius: 12px;
        }
        width: 35%;
        object-fit: cover;
    }

}