#add_rounded_button{
    display: flex;
    justify-content: center;
    &.default{
        ion-button{
            width: 56px;
            height: 56px;
        }
        p{
            font-size: 16px;
        }
    }
    &.small {
        ion-button{
            width: 26px;
            height: 26px;
            --background: var(--ion-color-success);
            ion-icon{
                font-size: 16px;
                &.add_rounded_button_add{
                    color: var(--ion-color-light);
                }
            }
        }
        p{
            font-size: 16px;
            width: calc(1ch + 16px);
            letter-spacing: -0.16px;
        }
    }
    ion-button{
        --box-shadow: none;
        --padding-end: 0;
        --padding-start: 0;
        --background: var(--ion-color-medium);
        color: var(--ion-color-dark);
    }
    p{
        width: calc(1ch + 32px);
        letter-spacing: -0.32px;
        line-height: 20px;
        text-align: center;
        margin: auto 0;
    }
}