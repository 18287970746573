#store_item {
    display: flex;
    cursor: pointer;
    max-width: 500px;
    margin: 8px;
 
    .store_item_description{
        padding: 8px 0 8px 8px;
        h3{
            margin: 0;
            font-size: 16px;
        }
        p{
            margin: 0;
            padding-top: 4px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2; /* number of lines to show */
            -webkit-box-orient: vertical;
            font-size: 14px;
            color: rgba(var(--ion-color-dark-rgb), 0.5);
            line-height: 20px;
        }
        span{
            font-size: 14px;
            padding-top: 8px;
            font-weight: 500;
        }
    }
    .store_item_image{
        margin-left: 8px;
        display: flex;
        ion-img{
            width: 120px;
            height: 100px;
            object-fit: cover;
        }
        justify-content: center;
        align-items: center;

    }
}

@media (min-width: 600px) {
    #store_item {
        transition: transform .2s;
        &:hover{
            transform: scale(1.02);
            border: .1px solid var(--ion-color-dark); 
        }
        .store_item_image{
            ion-img{
                width: 140px;
                height: 120px;
            }
        }
    }
  }