#location_selector{
    padding: 8px 0;
    line-height: 20px;
    cursor: pointer;
    p{
        margin: 0;
        font-weight: 500;
        color: var(--ion-color-dark);
        font-size: 14px;
        display: flex;
        align-items: center;
        ion-icon{
            margin-left: 8px;
        }
    }
    span{
        font-size: 12px;
        color: rgba(var(--ion-color-dark-rgb), 0.5);
    }
}