ion-tab-bar{
    display: flex;
    transition: .1s ease-in-out;
    &.hide{
        display: none;
    }
    ion-tab-button{
        --padding-end: 8px;
        --padding-start: 8px;
    }
}