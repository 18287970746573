#order_product{
    ion-item{
            width: 100%;
            --inner-padding-top: 16px;
            --inner-padding-bottom: 16px;

        .order_product_title{
            h3{
                margin: 0 0 8px 0;
                font-weight: 600;
                font-size: 16px;
            }
            p{
                margin: 0;
                font-size: 14px;
                line-height: 20px;
                color: rgba(var(--ion-color-dark-rgb), 0.5);
            }
        }
        ion-note{
            margin-bottom: auto;
            font-size: 16px;
            
            &.order_product_quantity{
                margin-top: 16px;
                margin-right: 12px;
                background: var(--ion-color-medium);
                padding: 0 12px;

            }
            &.order_product_total{
                
            }
        }

    }
}