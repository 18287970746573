#store_header_info{
    padding: 16px 0;
    .store_header_info_description{
        padding: 0 16px;
        p{
            margin: 0;
            font-size: 14px;
        }
    }
}
@media (min-width: 600px) {
    #store_header_info {
        margin-top: -125px;
        color: white;
        position: relative;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.72), rgba(0, 0, 0, 0) 100%);
        .store_header_info_title{
            ion-toolbar{
            --background: transparent;
                color: white;
            }
        }
    }
}