#section_title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 8px;
    margin: 16px 8px 0 8px;
    width: 100%;
  h3{
    margin: 0;
    font-size: 18px;
    font-weight: 500;
  }  
  p{
      margin: 0;
      font-size: 16px;
      min-width: 100px;
      text-align: right;

  }
  ion-button{
    --padding-start: 0;
    --padding-end: 0;
    font-size: 14px;

  }
}