ion-modal.cart_bottom_sheet_content {
  #ion-react-wrapper{
        ion-content{
            height: 90vh;
            &::part(scroll){
                overflow-y: hidden;
            }
            ion-list{
                height: 100%;
                overflow-y: auto;
            }
        }
    }
}
