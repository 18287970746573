
@font-face {
    font-family: "Pacifico";
    src: url("../assets/fonts/Pacifico-Regular.ttf");
    font-style: normal;
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: "Poppins";
    src: url("../assets/fonts/Poppins-Thin.ttf");
    font-style: normal;
    font-weight: 100;
    font-display: swap;
}

@font-face {
    font-family: "Poppins";
    src: url("../assets/fonts/Poppins-ExtraLight.ttf");
    font-style: normal;
    font-weight: 200;
    font-display: swap;
}

@font-face {
    font-family: "Poppins";
    src: url("../assets/fonts/Poppins-Light.ttf");
    font-style: normal;
    font-weight: 300;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-weight: 400;
    src: url('../assets/fonts/Poppins-Regular.ttf');
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-weight: 500;
    src: url('../assets/fonts/Poppins-Medium.ttf');
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-weight: 600;
    src: url('../assets/fonts/Poppins-SemiBold.ttf');
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    src: url('../assets/fonts/Poppins-Bold.ttf');
}


