#login_screen {
    .login_screen_background {
        background: url('./login-background.webp');
        background-size: cover;

        .login_screen_box {
            display: flex;
            flex-direction: column;

            height: 100vh;
            width: 100vw;
            background: rgba(var(--ion-color-success-rgb), 0.97);
            


            .login_screen_content_skip {
                margin-left: auto;
                text-transform: none;
                margin-top: 4px;
            }

            .login_screen_content {
                padding: 16px;
                display: flex;
                flex-direction: column;
                width: 100%;
                height: 100%;
                text-align: center;
                ion-button {
                    text-transform: none;
                    --box-shadow: none;
                }
                
                .login_screen_action{
                    display: flex;
                    flex-direction: column;
                    margin-top: auto;
                    padding-bottom: 60px;
                    p{
                        color: white;
                        font-size: 16px;
                        font-weight: 600;
                        margin: 0 0 8px 0;
                        text-align: center;
                    }
                    ion-button {
                        height: 52px;
                    }
                    p.country_icon{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: #000000;
                        margin: 0 8px;
                        ion-icon{
                            width: 32px;
                            height: 32px;
                        }
                    }
                }

            }
            span.login_screen_privacy {
                margin-top: auto;
                padding: 16px;
                color: #d8d8d8;
                line-height: 16px;
                font-size: 12px;
            }
        }
    }
}


@media (max-width: 2080px) {
    .login_screen_box {
        margin-left: auto;
        max-width: 411px;
    }
}


@media (max-width: 600px) {
    .login_screen_background {
        .login_screen_box {
            max-width: 100%;
            background-image: url('./login-background.png') !important;
            background-size: cover !important;
            .login_screen_content {
                background: linear-gradient(0deg, rgba(0, 0, 0, 0.72), rgba(0, 0, 0, 0) 100%);
            }
        }
    }
}