#order_status_content{
    height: 70vh;
    h3{
        margin: 0 0 16px 0;
    }
    img{
        display: flex;
        margin: auto;
        width: 500px;
    }
}