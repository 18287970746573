#item_header_info{
    padding: 8px 0;
    h3{
        padding: 0px 16px 6px;
        font-size: 34px;
        font-weight: 700;
        margin: 0;
    }
    .item_header_info_description{
        padding: 0 16px 16px 16px;
        p{
            margin: 0;
            font-size: 14px;
            line-height: 20px;
            color: rgba(var(--ion-color-dark-rgb), 0.5);

        }
    }
}