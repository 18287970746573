#item_header {
    // position: absolute;
    position: sticky;
    ion-back-button{
        display: flex;
        justify-content: center;
        align-items: center;
        &::part(native){
            width: 40px;
            height: 40px;
        }
        --background: var(--ion-color-light);
    }
    ion-button{
        width: 40px;
        height: 40px;
    }
}