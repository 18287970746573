ion-footer#view_cart_footer{
    position: absolute;
    transition: .3s ease-in-out;
    transform: translateY(0px);
    bottom: 0;
    &.up{
        transform: translateY(-64px);
    }
    &.down{
        transform: translateY(-16px);
    }
    padding: 0 16px;
    ion-button{
        height: 56px;
        max-width: 400px;
        margin: auto;
        --box-shadow: none;
    }
}