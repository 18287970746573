#item_footer{
    background: linear-gradient(rgba(255, 255, 255, 0) 2.5%, rgba(255, 255, 255, 0.92) 55.35%);
    padding: 24px 16px;
    
    ion-button{
        margin: auto;
        max-width: 400px;
        --box-shadow: none;
        text-transform: none;
        height: 56px;
        span{
            margin-left: auto;
        }
    }
}