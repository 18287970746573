#place_item{
    --inner-padding-bottom: 8px;
    --inner-padding-top: 8px;
    ion-label{
        h3{
            font-weight: 500;
            font-size: 16px;
        }
    }
    ion-button{
        height: 40px;
        width: 40px;

    }
}